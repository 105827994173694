import { promiseTimeout } from '@vueuse/shared'
export default function (time) {
  const smsBtnText = ref('获取验证码'); const count = ref(time)
  const fetchData = async () => {
    count.value--
    if (count.value === 0) {
      pause()
      count.value = time
      return smsBtnText.value = '重新获取'
    }
    smsBtnText.value = `${count.value}s`
  }
  const { isActive, pause, resume } = useTimeoutPoll(fetchData, 1000)

  return {
    smsBtnText,
    resume,
    isActive,
  }
}
